import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Header from '../components/header'
import Footer from '../components/footer'
import Seo from '../components/seo'

const ListUsedForklifts = ({data}) => {
    const entry = data.markdownRemark
    //console.log('entry: ', data);
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname="/" />
            <Header />
            <div className="layout banner_bg grey">
                <div className="banner__container relative">
                    <div className="absolute banner_img">
                        <GatsbyImage image={entry.frontmatter.image.childImageSharp.gatsbyImageData} alt={entry.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                    </div>
                    <div className="banner relative">
                        <h2>{entry.frontmatter.heading}</h2>
                    </div>
                </div>
            </div>
            <main className="layout list_entry grey">
                <div className="entry__container">
                    <div className="entry__flex flex__space-between">
                        <article className="article">
                            <div className="text used_margin" dangerouslySetInnerHTML={{ __html: entry.html }} />
                            <div>
                                {entry.frontmatter.products.map( (item, i) => (
                                <div key={`product_${i}`} className="used__flex flex__space-between">
                                    <div className="used__20 used_img">
                                        <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} alt={item.model} imgStyle={{ objectFit: "contain" }} style={{ display: "block" }} />
                                    </div>
                                    <div className="used_text used__70">
                                        <h3>{item.model}</h3>
                                        <p><strong>Brand:</strong> {item.brand}</p>
                                        <p><strong>Load Capacity:</strong> {item.load_capacity}</p>
                                        <p><strong>Engine:</strong> {item.engine}</p>
                                        <p><strong>Mast:</strong> {item.mast}</p>
                                        <p><strong>Tyres:</strong> {item.tyres}</p>
                                        <p><strong>Price:</strong> {item.price}</p>
                                        <div className="btn_used_margin">
                                            <Link to={`/contact-us/?product=${item.model}`} className="btn__used">Enquire now</Link>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                            <div className="text">
                                <p>{entry.frontmatter.contact}</p>
                            </div>
                        </article>
                        <aside className="aside">
                            {entry.frontmatter.aside.map( (item, i) => (
                                <Link key={`item_` + i + ``} to={item.url} title={item.title}>
                                    <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} alt={item.title} style={{ display: "block" }} imgStyle={{ objectFit: "contain" }} />
                                </Link>
                            ))}
                        </aside>
                    </div>
                </div>
            </main>            
            <Footer />
        </div>
    )
}

export default ListUsedForklifts;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
				meta_description
                heading
                image {
                    childImageSharp {
                        gatsbyImageData(
                            width: 800, 
                            quality: 100,
                            placeholder: NONE
                        )
                    }
                }
                aside {
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 272, placeholder: NONE)
                        }
                    }
                    title
                    url
                }
                products {
                    model
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 150, placeholder: NONE)
                        }
                    }
                    brand
                    load_capacity
                    engine
                    mast
                    tyres
                    price
                }
                contact
			}
			fields {
                slug
			}
			html
		}
    }
`
